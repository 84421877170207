import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { END } from "redux-saga";
import wrapper from "../../redux/store/store";
import MicroSite from "../../components/common/microSite";
import { FnpPageType, setFnpPageType } from "../../src/utils/common";
import { errorInMicroSitePage, requestCMSMicroSitePageData } from "../../src/actions/cms-microSite-actions";
import CMSPageHead from "../../components/common/cms/cms-page-head";
import useCommonOmnitureSetup from "../../components/hooks/analytics/useCommonOmnitureSetup";
import { requestAppBannerData, requestWidgetHelperData } from "../../src/actions/common-page-actions";
import { useSetCurrencyDetailsToWindow, useReloadWidgetsPincodeChange } from "../../src/utils/hooks";
import useClevertapSetup from "../../components/hooks/analytics/clevertap/useCleverTapSetup";
import { APP_REDIRECTION_BANNER_ENDPOINT } from "../../components/common/constants";

/**
 * This method brings in all data which has to be displayed on the info page
 *
 * @returns {React.ReactElement} jsx for the info page.
 */
const MicroSitePages = () => {
  useSetCurrencyDetailsToWindow();
  useReloadWidgetsPincodeChange();
  const { breadcrumbs } = useSelector((state) => state.commonPageData);
  const { data: microSitePageData } = useSelector((state) => state.cmsMicroPage);

  const microSiteData = {
    microSite: microSitePageData,
    pageType: FnpPageType.INFO,
    breadcrumbs,
  };

  useEffect(() => {
    setFnpPageType(FnpPageType.INFO);
  }, []);

  useCommonOmnitureSetup();
  useClevertapSetup();

  return (
    <>
      <CMSPageHead />
      <MicroSite microSiteData={microSiteData} />
    </>
  );
};

MicroSitePages.getInitialProps = wrapper.getInitialPageProps((store) => async (ctx) => {
  const isServer = typeof window === "undefined";
  // for getting trace id serverside logs in kibana
  if (isServer && global) {
    global.pageContext = ctx;
  }
  const { asPath } = ctx;
  let [pathParam] = asPath.split("?");
  if (pathParam.charAt(0) === "/") {
    pathParam = pathParam.substring(1);
  }

  const appBannerOptions = {
    contentURL: APP_REDIRECTION_BANNER_ENDPOINT,
    headers: ctx?.req?.headers,
    ctx,
    setResHeaders: true,
  };

  if (ctx.req) {
    await store.dispatch(requestAppBannerData(appBannerOptions));
    await store.dispatch(requestWidgetHelperData());
    if (ctx?.query?.slug1 !== "_pp") {
      try {
        const contentOptions = {
          contentURL: pathParam,
          headers: ctx?.req.headers,
          ctx,
          setResHeaders: true,
        };
        await store.dispatch(requestCMSMicroSitePageData(contentOptions));
      } catch (error) {
        store.dispatch(errorInMicroSitePage(error.message));
      }
    }
    // Stop the saga if on server
    store.dispatch(END);
    await store.sagaTask.toPromise();
  }

  return {};
});

export default MicroSitePages;
